.pd-l-2px{
    padding: 0px 0px 0px 2px;
}

.css-19r6kue-MuiContainer-root {
    padding-left:  0px !important;
    padding-right: 0px !important;
}
.bg-primary{
    color: #1976d2;
}
.bg-gray{
    color:#5e5a5a !important;
}
.bg-clr-F4F6FD{
    background-color: #F4F6FD;
}
.p-0{
    padding: 0px !important;
}
/* .scroollauto{
    overflow: auto;
height: calc(100vh - 49px);
} */
main{
    padding: 0px !important;
}

.m-r-5{
    margin-right: 5px;
}

.cust-badge{
    background: #006997;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-100{
    width: 100%;
}