.bg-header .css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
}

.back-color {
    background: #fff !important;
    color: black !important;
}

.bg-header .css-1191obr-MuiPaper-root-MuiAppBar-root {
    background: #fff !important;
    color: black !important;
}

.sm-header .css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
}

.sm-header .css-1191obr-MuiPaper-root-MuiAppBar-root {
    background: #fff !important;
    color: black !important;
    width: calc(100% - 237px) !important;
}

.search-container.css-1cejdov {
    display: flex;
    width: auto !important;
    background: transparent !important;
    border: 1px solid black;
    align-items: center;
}

.bg-header .css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    width: calc(100vw - 66px);
}

.sm-header .css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    width: 100%
}

/* .headermb{
    width: 95.6% !important;
} */

/* #demo-select-small{
    padding: 0px;
} */

.serch-dr {
    border: 1px solid #c4c4c4 !important;
    padding: 4px !important;
    border-radius: 5px !important;
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    padding-left: 10px !important;
}

.search-container.css-1cejdov {
    display: flex;
    width: auto !important;
    background: transparent !important;
    border: 1px solid #c4c4c4 !important;
    align-items: center !important;
    border-left: 0px !important;
    margin: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.serch-dr .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    left: 137px !important;
    top: 54px !important;
}

.css-168noda {
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.css-vwd6ro {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.bg-header .css-168noda {
    width: calc(100vw - 65px)
}