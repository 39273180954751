@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&family=Roboto:wght@500&display=swap');

html{
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:'Roboto';
}

.mar-0{
  margin: 0px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-normalize {
  text-transform: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.line-height-normal {
  line-height: normal !important;
}

.font-size-12px{
  font-size: 12px;
}

.dialogCloseClr{
  color:#0000 !important
}

.ipt-size{
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.text-right{
  text-align: right;
}

.pd-r-10px {
  padding: 0px 10px 0px 0px;
}

.nav-highlight {
  text-decoration: underline;
  color: #157ce2;
  cursor: pointer;
}

.pd-r-15px{
  padding: 0px 15px 0px 0px;
}

.pd-l-10px{
  padding: 0px 0px 0px 10px;
}

.pd-0 {
  padding: 0px !important;
}

.pd-l-5px {
  padding-left: 5px;
}

.pd-b-10px {
  padding-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled-color{
  color: #aba8a8;
}

.inpt-height{
  height: 30px !important;
}

.rm-brdr{
  border-radius: 0px !important;
}
/* 
.MuiOutlinedInput-root{
  border-radius: 0px !important;
} */

.f-w-600{
  font-weight: 600;
}

.d-flex {
  display: flex;
}

.align-item-center {
  align-items: center;
}