.sidebar_bg .MuiPaper-root{
    background-color: #1976d2;
    color:#ffffff;
}

.MuiListItemIcon-root{
    color: #ffffff !important;
}


/* selected button color change */
.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root.Mui-selected{
    margin: 0px 8px;
    background: #5dabf8 !important;
    color: #ffffff;
    border-radius: 6px;
}

.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root.Mui-selected .css-10hburv-MuiTypography-root{
    position: relative;
    left: -10px;
}

.Mui-selected .apply-btn{
    color: #ffffff ;
    background: #5dabf8 !important;
    border-radius: 6px;
    padding: 6px 8px !important;
}

.Mui-selected .apply-btn{
    position: relative !important;
    left: 0px !important;
}

.Mui-selected .no-apply-btn{
    position: relative !important;
    left: -8px !important;
}

.break-space{
    white-space: break-spaces;
}

/* child selected styles */
.css-1i6lvca-MuiButtonBase-root-MuiListItemButton-root.Mui-selected.child-selected{
    background-color: #90c4f7;
    color: #ffffff;
    border-radius: 6px;
    margin: 6px 7px !important;
    height: 40px;
}

.css-1i6lvca-MuiButtonBase-root-MuiListItemButton-root.Mui-selected.child-selected:hover{
    background-color: #90c4f7;
    color: #ffffff;
    border-radius: 6px;
    margin: 6px 7px !important;
    height: 40px;
}

.css-1i6lvca-MuiButtonBase-root-MuiListItemButton-root.Mui-selected.child-selected .css-10hburv-MuiTypography-root{
    position: relative;
    left: -10px;
}

/* small drawer style */
.small-drawer .MuiPaper-root{
    background-color: #1976d2;
    color:#ffffff;
}

.css-gcf7nn.Mui-selected{
    margin: 0px 8px;
    background: #5dabf8 !important;
    color: #ffffff !important;
    border-radius: 6px;
}

.css-gcf7nn.Mui-selected .css-vubbuv{
    position: relative !important;
    left: -7px !important;
}

.css-gcf7nn.Mui-selected .css-yb0lig{
    position: relative !important;
    left: -8px !important;
}

/* .css-gcf7nn:hover{
	margin: 0px 8px;
    background: #5dabf8 !important;
    color: #ffffff !important;
    border-radius: 6px;
} */

.css-jn27em.Mui-selected{
	background-color: #90c4f7 !important;
    color: #ffffff !important;
    border-radius: 6px;
    margin: 6px 7px !important;
    height: 40px;
}

.css-jn27em.Mui-selected .css-yb0lig{
    position: relative !important;
    left: -9px !important;
}

.css-jn27em.Mui-selected:hover{
	background-color: #90c4f7 !important;
    color: #ffffff !important;
    border-radius: 6px;
    margin: 6px 7px !important;
    height: 40px;
}