.MuiDataGrid-columnHeaders{
    background: #1565c0 !important;
    color: #ffffff !important;
}

/* .css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
    font-weight: 600 !important;
} */

.hide-header .MuiDataGrid-columnHeaders{
    display: none !important;
}

.lastcolumnSeparator .MuiDataGrid-columnSeparator--sideRight {
    display: none !important;
}

.css-1w5m2wr-MuiDataGrid-virtualScroller{
    margin-top: 45px !important;
}

.MuiDataGrid-columnHeaders{
    min-height: 45px !important;
    max-height: 45px !important;
}

.css-1jvt3rm-MuiDataGrid-root .MuiDataGrid-row.even{
    background-color: #D9E4F6;
}

.MuiDataGrid-row{
    cursor: pointer;
}